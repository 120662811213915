import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class NoLucrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cover: {
        image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fcover-visa-no-lucrativa.jpg?alt=media&token=c535aad0-651b-49f9-bca8-11df583da164`,
        title: `Visado No Lucrativo`
      }
    };
  }

  renderBack() {
    return (
      <div className="col-12">
        <Link to={`/visados`}>
          <h4>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
              className={`icon inline-block`}
              alt={`arrow`}
            />
            <span className="pl-2">Atras</span>
          </h4>
        </Link>
      </div>
    );
  }

  renderArrow() {
    return (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
        className={`icon`}
        alt={`arrow`}
      />
    );
  }

  renderCoverImage() {
    const { image, title } = this.state.cover;
    return <img src={image} className={`img-quote`} alt={title} />;
  }

  renderContent() {
    return (
      <div className="text-content pt-4 pb-5">
        <h5 className="">
          <span className="medium">
            ¿Se puede vivir en España como residente legal sin trabajar?
            <br />
            <br />
          </span>
          El extranjero que desee residir temporalmente en España sin realizar
          actividades laborales o profesionales, deberá solicitar personalmente
          el correspondiente visado en la misión diplomática u oficina consular
          española de su demarcación de residencia.
        </h5>
      </div>
    );
  }

  renderQandA() {
    return (
      <div className="text-content py-4">
        <h3 className="py-3">Existen muchas dudas en torno a este visado:</h3>
        <h5 className="py-2">
          <span className="bold">¿Dónde se puede tramitar este visado?</span>
          <br />
          <br />
          La autorización de residencia No Lucrativa, es el único caso que se
          debe aplicar desde el consulado o entidad diplomática de la
          demarcación de residencia, no se puede tramitar estando dentro de
          España.
        </h5>

        <h5 className="py-2">
          <span className="bold">
            ¿Cuánto dura la residencia No Lucrativa? ¿Se puede renovar?
          </span>
          <br />
          <br />
          Este visado tiene duración de un (1) año desde el momento que fue
          estampado el visado y se podrá renovar por un plazo de dos (2) años
          más, siempre que se mantengan los requisitos exigidos.
        </h5>

        <h5 className="py-2">
          <span className="bold">¿Se puede trabajar con este visado?</span>
          <br />
          <br />
          No, la autorización de residencia No Lucrativa no permite trabajar
          durante el primer año. Se puede invertir en un negocio o estudiar
          durante su vigencia. Luego de transcurrido ese periodo, se podrá
          solicitar una modificación de la residencia, que conceda la
          posibilidad de trabajar por cuenta ajena o cuenta propia.
        </h5>

        <h5 className="py-2">
          <span className="bold">
            ¿Cuál es el monto de los recursos económicos que deben aportarse?
          </span>
          <br />
          <br />
          Para su sostenimiento mensualmente, el 400% del IPREM (25.817 euros).{" "}
          <br />
          Para el sostenimiento de sus familiares, el 100% del IPREM (6.454
          euros por cada familiar.
        </h5>

        <h5 className="py-2">
          <span className="bold">
            ¿Se puede entrar y salir de España varias veces con este visado?
          </span>
          <br />
          <br />
          Sí. Siempre que no se superen los 180 días fuera de España.
        </h5>
        <h5 className="py-2">
          <span className="bold">
            ¿Cuál es el plazo para recibir la resolución?
          </span>
          <br />
          <br />
          El plazo de resolución es de aproximadamente tres meses. Estos tres
          meses son contados a partir del día siguiente a la fecha en que haya
          "tenido entrada en el registro del órgano competente para su
          tramitación”.
        </h5>
        <h5 className="py-2">
          <span className="bold">
            ¿Qué pasa si transcurre el plazo sin que la Administración haya dado
            una resolución?
          </span>
          <br />
          <br />
          En ese caso se podrá entender que la solicitud ha sido denegada por
          silencio administrativo.
        </h5>

        <h5 className="py-2">
          Ventajas de la residencia no lucrativa
          <ul className="px-5 pt-2 tc">
            <li>
              Permite obtener la residencia permanente, llamada residencia de
              larga duración a los cinco (5) años. El tiempo de residencia
              también será computable para la solicitud de la nacionalidad al
              año, dos años, o diez años; dependiendo del caso concreto
            </li>
            <br />
            <li>
              Permite valorar durante el primer año las mejores opciones para
              permanecer en España.
            </li>
            <br />
          </ul>
        </h5>

        <div className="pb-5" />
      </div>
    );
  }

  render() {
    const { title } = this.state.cover;
    return (
      <div className="container">
        <Menu />
        <div className="pt-15">
          <div className="row">
            {this.renderBack()}
            <div className="col-12 py-3">{this.renderCoverImage()} </div>
            <div className="col-12">
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">{title}</span>
              </h4>
              {this.renderContent()}
              <hr />
              {this.renderQandA()}
            </div>
            {this.renderBack()}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default NoLucrativo;
