import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Inversor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fmadrid.jpeg?alt=media&token=edaeda1b-db82-4d57-a0a6-3585b7328663`,
				title: `Visado o autorización de residencia para Inversor`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/visados`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="">
					Podrán solicitar esta autorización de residencia los extranjeros que
					quieran entrar en territorio español con el fin de realizar una
					inversión inicial de capital, entendiéndose por tal:
				</h5>

				<h4 className="pt-3">
					<span className="bold">1. Inversión en activos financieros</span>
					<ul className="px-5 pt-3 tc">
						<li>Deuda pública española (2.000.000 €)</li>
						<br />
						<li>
							Acciones o participaciones de sociedades de capital españolas
							(1.000.000 €)
						</li>
						<br />
						<li>
							Fondos de inversión o fondos de capital de riesgo constituidos en
							España (1.000.000 €)
						</li>
						<br />
						<li>
							Depósitos bancarios en entidades financieras españolas (1.000.000
							€)
						</li>
						<br />
						<li>
							Entidades de Tenencias de Valores Extranjeros ETVE (Holdings
							Empresariales)
						</li>
						<br />
					</ul>
				</h4>
				<h4 className="pt-3">
					<span className="bold">
						2. Inversión en inmuebles (valor igual o superior a 500.000 €)
						Golden Visa
					</span>
					<ul className="px-5 pt-3 tc">
						<li>
							La compra de cualquier tipo de bien inmueble sin distinción de su
							uso, a partir de 500.000 €, siempre que esté libre de cargas y
							gravámenes
						</li>
						<br />
						<li>
							Pueden ser uno o varios inmuebles que sumados alcancen la cifra
							exigida
						</li>
						<br />
						<li>
							La inversión se acredita a través del correspondiente certificado
							en el Registro de la Propiedad y la escritura de la compra de el o
							los inmuebles.
						</li>
						<br />
					</ul>
				</h4>
			</div>
		);
	}

	renderQandA() {
		return (
			<div className="text-content py-4">
				<h3 className="py-3">
					Algunas de las preguntas más comunes sobre este visado:
				</h3>
				<h5 className="py-2">
					<span className="bold">
						¿Cuánto dura la residencia como inversor? ¿Cuándo se debe renovar?
					</span>
					<br />
					<br />
					La duración inicial de la autorización será de dos (2) años, pudiendo
					solicitar la renovación por períodos sucesivos de cinco (5) años,
					siempre y cuando se mantengan las condiciones que generaron el
					derecho.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Es posible realizar la inversión a través de una Sociedad?
					</span>
					<br />
					<br />
					Sí, es perfectamente viable que el titular de la inversión sea una
					Sociedad en donde funja como socio y con un monto de inversión inicial
					de 1.000.000 €.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Cómo se puede acreditar la titularidad de la vivienda?
					</span>
					<br />
					<br />
					Para poder acreditar la titularidad de la vivienda, se debe aportar la
					escritura de esa compraventa y el certificado del registro de la
					propiedad (con no más de 90 días de emitido). <br />
					<br />
					Si en el momento de la solicitud del visado, la adquisición de los
					inmuebles se encontrara en trámite de inscripción en el Registro de la
					Propiedad, será suficiente la presentación de la citada certificación
					en la que conste vigente el asiento de presentación del documento de
					adquisición, acompañada de documentación acreditativa del pago de los
					tributos correspondientes.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Cuanto tiempo puede tener la compra de la vivienda para solicitar
						el visado?
					</span>
					<br />
					<br />
					Puede tener un tiempo de adquisición hasta de un (1) año, al momento
					de la solicitud de la autorización de residencia por inversor.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Dónde se puede solicitar la visa de inversión?
					</span>
					<br />
					<br />
					Existen dos opciones:
					<br />
					<br />
					<ul className="tc px-3">
						<li>
							<span className="bold">Fuera de España</span>
							<br />
							Se podrá solicitar el visado por inversión en el Consulado de
							España del país en donde se esté residiendo legalmente.
						</li>
						<br />
						<li>
							<span className="bold">Dentro de España</span>
							<br />
							Se podrá solicitar desde España, siempre que se encuentre dentro
							de los (90 días) de visado de turista o cualquier otra residencia
							legal en el territorio. En este caso, no necesitaría aplicar para
							un visado, sino para una autorización de residencia como inversor
							directamente a través de la Unidad de Grandes Empresas.
						</li>
						<br />
					</ul>
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Qué beneficios se obtienen con este visado?
					</span>
					<br />
					<br />
					<ul className="tc px-3">
						<li>
							No existe obligación de residir en España. Sin embargo, debe
							hacerse como mínimo una entrada al país durante la vigencia del
							visado
						</li>
						<br />
						<li>No existe obligación de ser residente fiscal</li>
						<br />
						<li>
							Se extiende al cónyuge y a los hijos menores de edad 0 mayores de
							21 años, si demuestra dependencia por enfermedad o discapacidad
						</li>
						<br />
						<li>Tránsito libre por todo el Territorio Schengen.</li>
						<br />
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderQandA()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Inversor;
