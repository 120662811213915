import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Estudiante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cover: {
        image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2FCover-Visa-Estudiante.jpg?alt=media&token=e249e424-356e-4508-ba66-d2d461da4c68`,
        title: `Visado de estancia para Estudiante`
      }
    };
  }

  renderBack() {
    return (
      <div className="col-12">
        <Link to={`/visados`}>
          <h4>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
              className={`icon inline-block`}
              alt={`arrow`}
            />
            <span className="pl-2">Atras</span>
          </h4>
        </Link>
      </div>
    );
  }

  renderArrow() {
    return (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
        className={`icon`}
        alt={`arrow`}
      />
    );
  }

  renderCoverImage() {
    const { image, title } = this.state.cover;
    return <img src={image} className={`img-quote`} alt={title} />;
  }

  renderContent() {
    return (
      <div className="text-content pt-4 pb-5">
        <h5 className="">
          Este visado habilita al extranjero para permanecer en el territorio,
          cursando cualquier tipo de estudio, trabajo de investigación,
          formación e intercambio de alumnos. También puede otorgarse para la
          realización de prácticas no laborales o servicios de voluntariado.
        </h5>
      </div>
    );
  }

  renderQandA() {
    return (
      <div className="text-content py-4">
        <h3 className="py-3">
          Las preguntas más comunes y nuevas modificaciones al visado de
          estudiante:
        </h3>
        <h5 className="py-2">
          <span className="bold">
            ¿Se puede solicitar la autorización de estancia estando en España?
          </span>
          <br />
          <br />
          Sí, de acuerdo a la nueva normativa, se puede solicitar la
          autorización para estudiar estando en España, siempre y cuando el
          ciudadano extranjero se encuentre en situación regular y comience el
          trámite de solicitud durante los primeros 60 días de vigencia de su
          autorización de permanencia como turista (90 días).
        </h5>

        <h5 className="py-2">
          <span className="bold">¿Qué documentación se debe presentar?</span>
          <ul className="px-5 pt-2 tc">
            <li>Admisión en un centro de estudios reconocido en España</li>
            <br />
            <li>
              Demostrar medios económicos para su sostenimiento, 100% del IPREM
              (537,84 € mensual, multiplicado por el número de meses que se
              extiendan sus estudios en España)
            </li>
            <li>Antecedentes penales apostillados</li>
            <li>Certificado médico</li>
            <li>Seguro médico.</li>
            <br />
            <br />
            *La Administración Consular tiene autoridad para evaluar y pedir
            documentos adicionales a los enumerados en estas instrucciones.
          </ul>
        </h5>

        <h5 className="py-2">
          <span className="bold">¿Por cuánto tiempo se otorga el visado?</span>
          <br />
          <br />
          El visado de estudiante se otorgan dependiendo del tiempo de duración
          de los estudios.
        </h5>

        <h5 className="py-2">
          <span className="bold">
            ¿Qué pasa si transcurre el tiempo de los 90 días y no se ha obtenido
            respuesta?
          </span>
          <br />
          <br />
          En la actualidad las autoridades de extranjería están demorando hasta
          cuatro meses en resolver las solicitudes de los estudiantes
          extranjeros. En ese caso, los solicitantes podrán permanecer en España
          hasta que se defina su residencia, aún cuando hayan agotado los 90
          días que la normativa les autoriza en condición de turistas.
        </h5>

        <h5 className="py-2">
          <span className="bold">¿Se puede trabajar con este visado?</span>
          <br />
          <br />
          El estudiante tiene la posibilidad de trabajar a tiempo parcial,
          presentando un contrato de trabajo por parte de su empleador, siempre
          que dicha actividad sea compatible con la realización de los estudios
          y que los ingresos obtenidos con el trabajo no sean el medio de
          subsistencia del estudiante en España.
        </h5>
        <h5 className="py-2">
          <span className="bold">
            ¿Quiénes pueden vivir en España con el estudiante?
          </span>
          <br />
          <br />
          Podrán venir con el estudiante, su cónyuge o pareja estable y sus
          hijos menores de edad o mayores dependientes económicamente o con una
          enfermedad con un grado de discapacidad, quienes podrán disfrutar de
          la estancia como estudiantes, sin posibilidad de trabajo por cuenta
          propia o ajena.
        </h5>

        <h5 className="py-2">
          Ventajas del visado de Estudiante
          <ul className="px-5 pt-2 tc">
            <li>
              Una vez culminados los estudios, se podrá solicitar una
              autorización de residencia por búsqueda de empleo, prácticas o
              proyecto empresarial, que reduce el tiempo obligatorio de 3 años
              para optar como residente
            </li>
            <br />
            <li>Es una opción de entrada a España rápida y segura</li>
            <br />
            <li>
              El estudiante puede solicitar el permiso para darse de alta como
              autónomo, presentando un proyecto empresarial ante el Ministerio
              de Trabajo, Migraciones y Seguridad Social.
            </li>
          </ul>
        </h5>

        <div className="pb-5" />
      </div>
    );
  }

  render() {
    const { title } = this.state.cover;
    return (
      <div className="container">
        <Menu />
        <div className="pt-15">
          <div className="row">
            {this.renderBack()}
            <div className="col-12 py-3">{this.renderCoverImage()} </div>
            <div className="col-12">
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">{title}</span>
              </h4>
              {this.renderContent()}
              <hr />
              {this.renderQandA()}
            </div>
            {this.renderBack()}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Estudiante;
