import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Practicas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fpracticas.jpeg?alt=media&token=da690e3d-6efd-447e-8472-5f835e6f739b`,
				title: `Visado o autorización de residencia para prácticas`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/visados`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="">
					Podrán solicitar la autorización de residencia por prácticas todos los
					extranjeros que hayan obtenido un título de educación superior en los
					dos (2) años anteriores a la fecha de solicitud, o que estén
					realizando estudios que conduzcan a la obtención de un título de
					educación superior en España o en el extranjero.
				</h5>
			</div>
		);
	}

	renderQandA() {
		return (
			<div className="text-content py-4">
				<h3 className="py-3">
					Surgen muchas dudas con respecto al visado de prácticas
				</h3>

				<h5 className="py-2">
					<span className="bold">¿ Qué documentación se debe presentar?</span>
					<ul className="px-5 pt-2 tc">
						<li>
							Convenio de prácticas o el contrato de prácticas que contenga:
							descripción y condiciones del programa relacionado con el mismo
							campo académico, nivel de cualificación obtenido y desglose de
							horas
						</li>
						<br />
						<li>
							Título de educación superior obtenido en los dos (2) años
							anteriores a la fecha de la solicitud o constancia de estudios que
							se estén realizando en ese momento, que conduzcan a la obtención
							de un título de educación superior
						</li>
						<li>Seguro médico</li>
						<li>
							Comprobantes de medios económicos suficientes. El 100% del IPREM
							mensual.
						</li>
					</ul>
				</h5>

				<h5 className="py-2">
					<span className="bold">¿Cuánto tiempo dura esta autorización?</span>
					<br />
					<br />
					El periodo de duración del permiso es de seis (6) meses o lo que
					establezca el Convenio.
				</h5>

				<h5 className="py-2">
					<span className="bold">Ventajas de la residencia para prácticas</span>
					<ul className="px-5 pt-2 tc">
						<li>Plazo abreviado de resolución (30 días hábiles)</li>
						<br />
						<li>
							Si no se resuelve en el plazo establecido, se entiende como
							concedida la autorización por silencio administrativo positivo.
						</li>
					</ul>
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Qué pasa si transcurre el tiempo de los 90 días y no se ha obtenido
						respuesta?
					</span>
					<br />
					<br />
					En la actualidad las autoridades de extranjería están demorando hasta
					cuatro meses en resolver las solicitudes de los estudiantes
					extranjeros. En ese caso, los solicitantes podrán permanecer en España
					hasta que se defina su residencia, aún cuando hayan agotado los 90
					días que la normativa les autoriza en condición de turistas.
				</h5>

				<h5 className="py-2">
					<span className="bold">¿Se puede trabajar con este visado?</span>
					<br />
					<br />
					El estudiante tiene la posibilidad de trabajar a tiempo parcial,
					presentando un contrato de trabajo por parte de su empleador, siempre
					que dicha actividad sea compatible con la realización de los estudios
					y que los ingresos obtenidos con el trabajo no sean el medio de
					subsistencia del estudiante en España.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Quiénes pueden vivir en España con el estudiante?
					</span>
					<br />
					<br />
					Podrán venir con el estudiante, su cónyuge o pareja estable y sus
					hijos menores de edad o mayores dependientes económicamente o con una
					enfermedad con un grado de discapacidad, quienes podrán disfrutar de
					la estancia como estudiantes, sin posibilidad de trabajo por cuenta
					propia o ajena.
				</h5>

				<h5 className="py-2">
					Ventajas del visado de Estudiante
					<ul className="px-5 pt-2 tc">
						<li>
							Una vez culminados los estudios, se podrá solicitar una
							autorización de residencia por búsqueda de empleo, prácticas o
							proyecto empresarial, que reduce el tiempo obligatorio de 3 años
							para optar como residente
						</li>
						<br />
						<li>Es una opción de entrada a España rápida y segura</li>
						<br />
						<li>
							El estudiante puede solicitar el permiso para darse de alta como
							autónomo, presentando un proyecto empresarial ante el Ministerio
							de Trabajo, Migraciones y Seguridad Social.
						</li>
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderQandA()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Practicas;
