import React from "react";

const SvgComponent = props => (
	<svg
		xmlnsXlink="http://www.w3.org/1999/xlink"
		className="img-fluid"
		viewBox="0 0 40 40"
		{...props}
	>
		<path
			opacity={1}
			fill="#000000"
			d="M20 2.1L25.9 14 39 15.9l-9.5 9.3 2.3 13.1L20 32.1 8.2 38.3l2.3-13.1L1 15.9 14.1 14z"
		/>
		<path
			fill="none"
			stroke="#000000"
			strokeMiterlimit={10}
			d="M20 1.9l5.9 11.9L39 15.7 29.5 25l2.3 13.1L20 31.9 8.2 38.1 10.5 25 1 15.7l13.1-1.9z"
		/>
	</svg>
);

export default SvgComponent;
