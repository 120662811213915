import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class PorqueEspana extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs2.jpeg?alt=media&token=c0596b9f-8782-4bca-b658-d44f0b21a83d`,
				title: `¿Por qué España?`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<ul>
					<li className="h4 pb-2">
						4ta posición del ranking comunitario en creación de empleo.
					</li>
					<li className="h4 pb-2">
						14 decimocuarta economía más grande del mundo.
					</li>
					<li className="h4 pb-2">
						13 país en el que mejor se vive en Europa.
					</li>
					<li className="h4 pb-2">
						Los inmigrantes representan más de 1 de cada 2 nuevos autónomos en
						el país.
					</li>
				</ul>

				<div className="py-2">
					<h3 className="">
						«España tiene una actitud muy positiva respecto a la migración y eso
						favorece el diseño de políticas"
					</h3>
					<a
						href="https://www.elcomercio.es/economia/espana-necesita-270000-inmigrantes-ano-asegurar-pensiones-20200118014423-ntvo.html"
						target="_blank"
						rel="noopener noreferrer"
					>
						<h4 className="link">Mas Info</h4>
					</a>
				</div>
				<div className="py-2">
					<h3 className="">
						«En las próximas tres décadas necesitaremos ocho o nueve millones de
						personas adicionales para mantener nuestra fuerza laboral en su
						nivel actual»
					</h3>
					<a
						href="https://www.burgosconecta.es/economia/pensiones/escriva-calcula-espana-20200117200353-ntrc.html"
						target="_blank"
						rel="noopener noreferrer"
					>
						<h4 className="link">Mas Info</h4>
					</a>
				</div>
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
						</div>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default PorqueEspana;
