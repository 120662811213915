import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class QuienesSomos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs2.jpeg?alt=media&token=c0596b9f-8782-4bca-b658-d44f0b21a83d`,
				title: `¿Quiénes somos?`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="pb-4">
					Somos un equipo de profesionales altamente cualificados, comprometidos
					con el proceso migratorio de nuestros clientes. Brindamos asesorías
					legales, financieras, comerciales y asistencia integral. Diseñando el
					traje a la medida de cada quien, basándonos en perspectivas reales de
					acuerdo a los distintos perfiles. Asumiendo como propio todo el
					camino, desde la salida de su país de origen hasta su integración en
					España.
				</h5>

				<h3 className="">Nuestra historia</h3>
				<h5 className="pb-4">
					Porta fue creado por Glen Colmenares, venezolana, abogada de
					profesión, con una amplia experiencia en el área de los negocios desde
					el punto de vista legal.
					<br />
					<br />
					Radicada en España desde el año 2018, a raíz de la agudización de la
					crisis en Venezuela, ha trabajado con diferentes inmigrantes,
					emprendedores, estudiantes y familias que, al igual que ella, vieron
					en España un lugar para establecerse.
					<br />
					<br />
					En su camino, realizó alianzas con personas que como ella, desean
					hacer más sencillo el proceso de cambio para los llamados “ciudadanos
					extranjeros”. Profesionales capacitados en diferentes áreas que fueron
					sumándose al proyecto de ayudar, tanto a quienes buscan la forma de
					comenzar a vivir y trabajar en España, como a quienes deseen
					materializar algún emprendimiento en el país.
					<br />
					<br />
					Así nace Porta, con la firme intención de escuchar, asesorar y
					acompañar a aquellos que decidieron salir de su zona de confort y
					emprender una nueva vida en el extranjero.
				</h5>
				<h3 className="">Nuestra Filosofía: “Always Listen”</h3>
				<h5 className="pb-4">
					Escuchamos y luego asesoramos. Nos tomamos el tiempo para comprender
					lo que necesita y luego analizamos las diferentes vías para obtener
					resultados satisfactorios.
				</h5>
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
						</div>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default QuienesSomos;
