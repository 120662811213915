import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Inversor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fstartup.jpeg?alt=media&token=ba0767d7-8c70-4a08-a8fe-477c7b7398df`,
				title: `Visado o autorización de residencia para emprendedores`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/visados`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="">
					En Porta somos expertos tramitando y gestionando este tipo de visado.
					Asesoramos al emprendedor en su modelo de negocio, partiendo de la
					idea inicial y luego canalizando su desarrollo e implantación en el
					mercado español.
					<br />
					<br />
					Podrán solicitar este visado todos aquellos extranjeros con espíritu
					emprendedor fuera de la UE, que vayan a desarrollar una actividad de
					carácter innovador con especial interés económico para España,
					presentando un plan de negocios.
					<br />
					<br />
					<span className="bold">
						Debe cumplir con una de las siguientes condiciones:
					</span>
					<ul className="px-5 pt-3 tc">
						<li>Creación de puestos de trabajo</li>
						<br />
						<li>
							Realización de una inversión con impacto socioeconómico de
							relevancia en el ámbito geográfico en el que se vaya a desarrollar
							la actividad, o
						</li>
						<br />
						<li>
							Aportación relevante a la innovación científica y/o tecnológica.
						</li>
						<br />
					</ul>
					Se deberá presentar un informe favorable de la Dirección General de
					Comercio Internacional e Inversiones del Ministerio de Industria,
					Comercio y Turismo para constatar las razones de interés general
					acreditadas inicialmente.
				</h5>
			</div>
		);
	}

	renderQandA() {
		return (
			<div className="text-content py-4">
				<h3 className="py-3">
					Algunas de las preguntas más comunes sobre este visado:
				</h3>
				<h5 className="py-2">
					<span className="bold">
						¿Cuánto dura el visado y/o residencia como emprendedor? ¿Cuándo se
						debe renovar?
					</span>
					<br />
					<br />
					Si se encuentran fuera de España, deberán solicitar un visado de
					residencia por un año.
					<br />
					<br />
					Si se tramita dentro de España, la duración inicial de la autorización
					de residencia será de dos (2) años, pudiendo solicitar la renovación
					por períodos sucesivos de dos (2) años, siempre y cuando se mantengan
					las condiciones que generaron el derecho.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Qué requisitos debe cumplir el plan de negocios?
					</span>
					<br />
					<br />
					Deberá incluir descripción del proyecto, el análisis de mercado,
					servicio o producto y la financiación, siempre que genere un valor
					añadido para la economía española, la innovación científica o
					tecnológica.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Si son varios socios en el proyecto, todos pueden optar por la
						residencia?
					</span>
					<br />
					<br />
					En caso de que existan varios socios, se evaluará la participación de
					cada uno de ellos. Así mismo se hará una revisión de los perfiles
					profesiones y aporte de conocimiento de cada uno al proyecto, su
					formación y experiencia profesional, así como su implicación en el
					proyecto.
				</h5>

				<h5 className="py-2">
					<span className="bold">¿Cuál es el capital para invertir?</span>
					<br />
					<br />
					No se requiere de un capital mínimo de inversión. Se debe demostrar
					disponer de la inversión inicial que indique el proyecto. Para la
					valoración del plan de negocios, se tendrá en cuenta especialmente y
					con carácter prioritario, que sea considerado de interés general para
					España, bien sea por la creación de puestos de trabajo, o por el
					carácter innovador del mismo.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Se puede trabajar por cuenta ajena con esta residencia?
					</span>
					<br />
					<br />
					Sí, la autorización de residencia para emprendedores, permite a los
					beneficiados trabajar de forma parcial en cualquier empresa, pero debe
					mantener su condición de autónomo y desarrollar su proyecto en
					paralelo, manteniendo los requisitos exigidos en la Ley.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Qué beneficios se obtienen con esta residencia?
					</span>
					<br />
					<br />
					<ul className="tc px-3">
						<li>
							Plazo abreviado de respuesta por parte de la Administración, en 20
							días hábiles
						</li>
						<br />
						<li>
							Se puede solicitar estando en España con visado de estancia de
							turista
						</li>
						<br />
						<li>Permite laborar desde el primer momento.</li>
						<br />
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderQandA()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Inversor;
