import React, { Component } from "react";
import MyContext from "./MyContext";

class MyProvider extends Component {
	state = {
		status: 0,
		index: 0,
		changeColor: false,
		font: `'Fira Mono', monospace`,
		changeWorkView: false
	};

	onChangeColor = () => {
		this.setState({
			changeColor: !this.state.changeColor
		});
	};

	onChangeWorkView = () => {
		this.setState({
			changeWorkView: !this.state.changeWorkView
		});
	};

	switchFont = newFont => {
		this.setState({ font: newFont });
	};

	render() {
		return (
			<MyContext.Provider
				value={{
					state: this.state,
					onChangeColor: this.onChangeColor,
					onChangeWorkView: this.onChangeWorkView,
					switchFont: e => this.switchFont(e)
				}}
			>
				{this.props.children}
			</MyContext.Provider>
		);
	}
}

export default MyProvider;
