import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class PerAC extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fpac.jpeg?alt=media&token=33ec54b1-89f7-461a-b62f-70e087403a16`,
				title: `Visado o autorización de residencia para Profesional Altamente Cualificado (PAC)`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/visados`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="">
					Este tipo de visado está diseñado para aquellos profesionales
					extranjeros con un perfil académico y profesional cualificado, cuando
					sea requerida su incorporación en territorio español para el
					desarrollo de una relación laboral o profesional.
				</h5>
			</div>
		);
	}

	renderQandA() {
		return (
			<div className="text-content py-4">
				<h3 className="py-3">
					Algunas de las preguntas más comunes sobre este visado:
				</h3>
				<h5 className="py-2">
					<span className="bold">
						¿Cuál es el tiempo de duración de este visado?
					</span>
					<br />
					<br />
					La Autorización de residencia para los Profesionales Altamente
					Cualificados tiene una duración de hasta dos (2) años, o la duración
					del contrato si fuese menor a dos (2) años.
				</h5>

				<h5 className="py-2">
					¿Quién es un Profesional Altamente Cualificado?
					<ul className="px-5 pt-2 tc">
						<li>
							Personal directivo o altamente cualificado. (Calificación nivel 1)
						</li>
						<br />
						<li>
							Graduados, postgraduados de universidades y escuelas de negocios
							de reconocido prestigio
						</li>
						<br />
					</ul>
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Se debe tener un salario muy alto para ser considerado PAC?
					</span>
					<br />
					<br />
					El empleador, a la hora de contratar al PAC, debe cumplir con los
					requisitos exigidos en la Ley y garantizar el salario que será el 150
					% más de lo que indique el BOE, de acuerdo al escalafón del perfil del
					profesional.
					<br />
					<br />
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Quiénes pueden venir a España junto al PAC?
					</span>
					<br />
					<br />
					El PAC podrá venir a España en compañía de su familia; entendiéndose
					como tal a su cónyuge o pareja estable y sus hijos menores de edad o
					mayores dependientes económicamente o con alguna discapacidad, que
					tendrán el mismo estatus legal del solicitante.
				</h5>

				<h5 className="py-2">
					<span className="bold">Ventajas de este visado</span>
					<br />
					<br />
					<ul className="tc px-3">
						<li>
							Es un procedimiento muy rápido en cuanto a plazos de resolución
						</li>
						<br />
						<li>
							Permite residir y trabajar en España y luego de un año de
							residencia modificar su estatus a otro tipo de residencia como
							cuenta ajena o propia
						</li>
						<br />
						<li>
							Los familiares del profesional pueden acompañarlo, siempre que se
							demuestren los medios económicos para su manutención.
						</li>
						<br />
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderQandA()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default PerAC;
