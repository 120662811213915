import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Empresas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fstartup2.jpeg?alt=media&token=3959fa5c-4fae-4e18-8b33-de3b81471c57`,
				title: `Empresas & Startups`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content py-4">
				<div className="row">
					<div className="col-md-6 col-12">
						<h5 className="">
							<span className="h5 font-serif medium m-0">
								“Si tú no trabajas por tus sueños, alguien te contratará para
								que trabajes por los suyos”.
								<br />
								-SteveJobs-
							</span>
						</h5>
					</div>
					<div className="col-md-6 col-12">
						<h5 className="">
							El espíritu emprendedor de quienes tienen grandes ideas y logran
							desarrollarlas, mueve al mundo. Sin embargo, muchas personas no
							saben cómo iniciar su proyecto empresarial.
							<br />
							<br />
							En Porta apoyamos las ideas que promuevan empresas innovadoras y
							eficientes, reconocemos la creatividad, el liderazgo y el
							esfuerzo. Por eso estamos en la constante búsqueda de personas con
							ideas de negocio, ofreciéndoles nuestros recursos desde el inicio
							hasta convertir su idea en realidad.
							<br />
							<br />
							Acompañamos a nuestros clientes y los hacemos parte de todo el
							proceso de constitución de su empresa. Basándonos en la
							retroalimentación constante en cada unas de las fases de creación.
						</h5>
					</div>
				</div>
			</div>
		);
	}

	renderFaseCreacion() {
		return (
			<div className="text-content py-4">
				<ol>
					<li className="h3 py-3">Metodología de trabajo</li>
					<h5 className="">
						En esta etapa nos enfocamos en cómo se deben expresar las ideas para
						generar interés, en los métodos de comunicación con los equipos de
						trabajo. También ponemos atención al cumplimiento de cada uno de los
						hitos que el proyecto va demandando para su materialización.
					</h5>
					<li className="h3 py-3">Modelo de negocio</li>
					<h5 className="">
						Lo más importante a la hora de diseñar el modelo de negocio de una
						empresa es la definición de una propuesta valor, alineada a la idea
						principal y al background o experiencia profesional de sus
						creadores, de esa manera es más sencillo afinar y preparar un
						business plan para lograr los objetivos planteados en el menor
						tiempo posible. Es importante considerar que aun cuando una idea
						pueda ser de gran interés para quien la concibe, es fundamental
						valorar y evaluar si la misma es sostenible en el tiempo, si es
						rentable y sobre todo, qué tan fuerte es el mercado de competencia.
					</h5>
					<li className="h3 py-3">Branding</li>

					<h5 className="">
						Lo primero que se debe saber es que el branding no es un LOGO.
						<br />
						<br />
						El branding es la construcción de la marca, lleva asociado un
						crecimiento continuo de toda la personalidad de la empresa con todo
						lo que esto implica. Una marca bien planteada desde su inicio es
						capaz de transformar tanto el modelo de negocio, como la imagen
						corporativa, la forma de comunicarse con el cliente y es posible
						hasta cambiar el modelo financiero.
						<br />
						<br />
						Pero de igual forma, habrá un logo.
					</h5>
					<li className="h3 py-3">Producto y usuario</li>
					<h5 className="">
						Las marcas modernas crean su estrategia y productos centradas en el
						usuario, lo que permite crear productos innovadores y competitivos
						de cara a la competencia.
						<br />
						<br />
						Jeff Bezos le dijo a Charlie Rose que el cliente se antepone a la
						innovación empresarial:
						<br />
						<br />
						"Definiría Amazon por nuestras grandes ideas, que son centradas en
						el cliente, colocando al cliente en el centro de todo lo que
						hacemos".
						<br />
						<br />
						Jeff Bezos (Amazon CEO) - Productos centrados en los usuarios
						<br />
						<br />
						<a
							href="https://www.myfeelback.com/en/blog/customer-centric-lessons-amazon"
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="link">Ver Mas</div>
						</a>
					</h5>
					<li className="h3 py-3">Marketing</li>
					<h5 className="">
						A la hora de establecer la mejor estrategia de marketing para una
						empresa, lo más importante no son los números, lo primero que se
						debe examinar es el tipo de negocio; no será lo mismo el marketing
						de una empresa de tecnología que el de una empresa de retail, por
						eso la importancia de cada fase de creación, en los que se obtiene
						el modelo de negocio, la personalidad de la marca, la definición de
						usuario y producto; esto podría indicar cómo atacar al mercado de
						forma efectiva y luego analizar cómo sustentarlo económicamente.
					</h5>
					<li className="h3 py-3">Modelo financiero</li>
					<h5 className="">
						En esta fase nos enfocamos en aterrizar la idea de negocio y crear
						un modelo financiero que se adapte a las necesidades de nuestro
						cliente. El planteamiento de Porta es ofrecer opciones de avance
						sobre escenarios realistas. Modelos financieros que sean flexibles
						de acuerdo a la evolución de cada proyecto y a los requerimientos
						del mercado.
						<br />
						<br />
						El modelo financiero se debe adaptar a las necesidades de quien lo
						sigue y no al revés. Es decir, se adaptará al tipo de mercado, a la
						idea desarrollada y al marketing empleado.
					</h5>
					<li className="h3 py-3">Estructura jurídica y legal</li>
					<h5 className="">
						Nuestro equipo está altamente capacitado para brindar asesorías
						sobre cuál es la mejor estructura jurídica y legal para llevar a
						cabo la creación de una empresa.
						<br />
						<br />
						Servicios mercantiles que ofrece Porta:
						<ul className="tc px-3">
							<li>Sociedad Limitada</li>
							<br />
							<li>Sociedades Anónimas</li>
							<br />
							<li>Sociedades Civiles y Cooperativas</li>
							<br />
							<li>Alta de autónomo</li>
							<br />
							<li>Registro de marca</li>
							<br />
							<li>Pacto de Socios</li>
							<br />
							<li>Tramitación de licencias y permisologías</li>
							<br />
							<li>Contratos mercantiles</li>
							<br />
						</ul>
					</h5>
				</ol>

				<h3 className="py-2">¿Porque hacerlo todo con nosotros?</h3>
				<h5 className="">
					<ul className="tc px-3">
						<li>
							Coherencia y consistencia en el lenguaje interno del equipo y de
							cara al cliente.
						</li>
						<br />
						<li>
							Establecemos canales de comunicación transparentes para evitar
							retrasos y malos entendidos con terceros.
						</li>
						<br />
						<li>
							Al hacer todos los trámites con el mismo equipo, los costos son
							inferiores que al hacerlos de forma individual.
						</li>
						<br />
						<li>
							Somos una startup, por lo que el avance y los resultados son mucho
							más rápidos en comparación con hacer todo por cuenta propia.
						</li>
						<br />
						<li>
							Hacemos equipo con el cliente, dándole participación en cada
							decisión de su proceso con nuestra metodología de workshops.
						</li>
						<br />
						<li>
							El trabajo es una combinación de entregas individuales de todos
							los involucrados y trabajo en equipo.
						</li>
						<br />
						<li>
							Se abren más posibilidades de conseguir inversionistas que quieran
							formar parte del proyecto.
						</li>
						<br />
					</ul>
				</h5>

				<h3 className="py-2">El Futuro</h3>
				<h5 className="pb-2">
					Porta tiene como objetivo apoyar, sustentar y sentar las bases para el
					éxito de los proyectos empresariales, en la evolución de los mismos,
					podemos brindar las herramientas necesarias para acercarlos a:
				</h5>
				<h5 className="">
					<ul className="tc px-3">
						<li>Incubadoras privadas y del estado</li>
						<br />
						<li>Instituciones del gobierno español para el emprendimiento</li>
						<br />
						<li>Inversión ángel</li>
						<br />
						<li>Franquiciado</li>
						<br />
						<li>Inversión en instrumentos tradicionales (banca)</li>
						<br />
						<li>
							Capital de nuevos socios (ayudamos a presentar los proyectos
							formados en Porta a inversionistas potenciales. Incluso Porta
							podría ser parte de dicha sociedad).
						</li>
						<br />
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		const header = "Empresas/Startups";

		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						<div className="hxl light text-center col-12">
							<div className="h-back">{header.toUpperCase()}</div>
						</div>
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderFaseCreacion()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Empresas;
