import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Otros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cover: {
        image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fnacionalidades.jpeg?alt=media&token=2ae7ca71-57a5-41e1-8647-8770d5c08324`,
        title: `Nacionalidad Portuguesa por origen Sefardí`
      }
    };
  }

  renderBack() {
    return (
      <div className="col-12">
        <Link to={`/visados`}>
          <h4>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
              className={`icon inline-block`}
              alt={`arrow`}
            />
            <span className="pl-2">Atras</span>
          </h4>
        </Link>
      </div>
    );
  }

  renderArrow() {
    return (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
        className={`icon`}
        alt={`arrow`}
      />
    );
  }

  renderCoverImage() {
    const { image, title } = this.state.cover;
    return <img src={image} className={`img-quote`} alt={title} />;
  }

  renderContent() {
    return (
      <div className="text-content pb-5">
        <h5 className="py-2">
          Los sefardíes o sefarditas son los judíos de Sefarad, o península
          ibérica asentados en estas tierras, según algunas estimaciones, desde
          los principios de la Era Común. La identificación de Sefarad con
          España fue posterior, tras la expulsión, según algunas versiones. Pero
          el término de sefardíes engloba tanto a los judíos expulsados por las
          Coronas de Castilla y Aragón en 1492, como a los desterrados del reino
          de Portugal en 1497. Cabe destacar, por delante de cualquier
          adscripción nacional, la peninsularidad de los expulsados de ambos
          territorios, ya que conformaban linajes familiares a los dos lados de
          la frontera castellano-portuguesa, estructurados por el constante
          periplo al que les obligaban las persecuciones inquisitoriales. Así se
          explica que una gran parte de los expulsados de Portugal lo fueran
          antes de Castilla y Aragón, donde tenían su origen.
          <br />
          <br />
          Sefardíes es, igualmente, una denominación extensiva a los
          descendientes de aquellos expulsados que a lo largo de los siglos
          hasta el presente conforman una diáspora por todo el mundo de
          identidad propia significada en la cultura ibérica, hispana y
          portuguesa.
          <br />
          <br />
          El desgarro familiar y social que provocaron las expulsiones, el
          sufrimiento que habían supuesto durante siglos las persecuciones y
          represalias hacia las comunidades judías, además del recrudecimiento
          de la represión a partir de las últimas décadas del siglo XV con la
          creación de la Inquisición, que procedía mediante torturas, embargos
          de bienes y sentencias de muerte, se cataloga, sin duda, como uno de
          los capítulos negros de la historia hispana.
          <br />
          <br />
          A ello respondió la intención de reparación histórica de los dos
          países de la península ibérica, que promulgaron diversas leyes
          compensatorias para que los descendientes de los judíos sefardíes,
          pudieran optar a la nacionalidad española y portuguesa.
          <br />
          <br />
          En Porta tenemos una amplia experiencia con la tramitación de las
          nacionalidades españolas por origen sefardí. Siendo que la ley
          promulgada por España ya está derogada, existe la posibilidad de la
          obtener la nacionalidad portuguesa y con ella, todos los derechos que
          de ella provengan.
          <br />
          <br />
          <br />
          <hr />
          <br />
          <br />
          <span className="pt-2 bold">
            Las preguntas más comunes sobre esta nacionalidad:
          </span>
          <br />
          <br />
          <span className="pt-2 bold">
            Si, ¿Se puede optar a la nacionalidad portuguesa por origen sefardí?
          </span>
          <br />
          <br />
          El gobierno de Portugal, publicó el 27 de febrero de 2015 el
          Decreto-Ley Nº 30-A/2015 mediante el cual se facilita la obtención de
          la nacionalidad portuguesa a todas aquellas personas que demuestren
          tener ascendencia sefardí.
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Cómo se puede comprobar el origen sefardí?
          </span>
          <br />
          <br />
          Se deberá realizar una investigación genealógica, en donde se pueda
          comprobar tener una conexión con los judíos exiliados de Portugal, es
          decir probar, una raíz judío sefardí en su ascendencia.
          <br />
          <br />
          <span className="pt-2 bold">¿Cómo certificar el origen sefardí?</span>
          <br />
          <br />
          A través de Certificado que emite la Federación de Comunidades Judías
          de Portugal.
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Si un apellido figura en las listas por internet, es ello alguna
            prueba de ser sefardí?
          </span>
          <br />
          <br />
          No, es sólo una referencia, pero realmente se debe realizar un
          investigación por un experto en la materia.
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Puedo aplicar con un estudio genealógico de un ascendiente sefardí
            español?
          </span>
          <br />
          <br />
          Sí, ya que, en el momento de la expulsión de los judíos sefardíes, la
          península ibérica estaba unida, por lo tanto, los sefardíes españoles,
          son también portugueses.
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Debo renunciar a mi nacionalidad anterior?
          </span>
          <br />
          <br />
          No, en Portugal se mantiene igual que en España, la no renuncia de su
          nacionalidad anterior, a los descendientes de sefardíes.
          <br />
          <br />
          <span className="pt-2 bold">
            Si uno de los cónyuges no puede aplicar por no tener ascendiente
            sefardí, ¿Podrá beneficiarse cuando el otro obtenga la nacionalidad?
            ¿Necesita residencia previa en Portugal?
          </span>
          <br />
          <br />
          Sí; podrá beneficiarse también por la naturalización. No, no se
          requiere la residencia previa en Portugal, pero sí deberá acreditar
          unos años mínimo de matrimonio y una fuerte vinculación con Portugal
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Qué diferencias existen entre los procesos de nacionalidad en
            España y Portugal?
          </span>
          <br />
          <br />
          La solicitud de la nacionalidad portuguesa por origen sefardí resulta
          más sencilla y se diferencia del trámite de nacionalidad española por
          cuanto:
          <br />
          <br />
          <ul className="px-5 pt-2 tc">
            <li>La ley portuguesa no exige la vinculación con España</li>
            <br />
            <li>
              La ley portuguesa no exige que el aplicante hable y/o conozca el
              idioma portugués y no requiere que se haga un examen de idioma ni
              de cultura
            </li>
            <li>
              No se requiere que la persona viaje a Portugal a firmar el acta de
              notoriedad, ya que la ley permite que se designe a un apoderado
              para la firma
            </li>
            <li>
              La ley no tiene fecha de caducidad, lo que implica que las
              personas van a poder realizar este trámite cuando deseen sin
              preocupaciones adicionales por la limitación del tiempo
            </li>
            <li>
              La ley no concede la ciudadanía a los hijos menores de edad que
              hayan nacido antes que el padre y/o la madre sefardí obtuviera la
              nacionalidad portuguesa.
            </li>
          </ul>
          <br />
          <br />
          Es debido a este antecedente histórico que la comunidad judía impulsó
          la creación de una ley para que los descendientes de judíos expulsados
          puedan solicitar la nacionalidad y tener derecho al retorno, siempre y
          cuando puedan acreditar que exista
          <br />
          <br />
          Dicha ley recibió el nombre de Decreto/Ley 30A/2015, siendo aprobada
          el 29 de enero del 2015 por el Gobierno portugués y entrando en vigor
          ese mismo año, permitiendo que aquellos interesados puedan aplicar
          para obtener la nacionalidad de dicho país y de alguna forma recuperar
          la herencia que se fue perdiendo con el pasar de los años.
          <br />
          <br />
          <span className="pt-2 bold">
            ¿Cómo es el proceso de obtención de la nacionalidad portuguesa por
            origen sefardí?
          </span>
          <br />
          <br />
          El proceso consta de varias fases, explicadas aquí a grandes rasgos:
          <br />
          <ol className="px-5 pt-2 tc">
            <li>
              Investigación de las líneas genealógicas de los antepasados hasta
              encontrar una raíz judío sefardí
            </li>
            <br />
            <li>
              Tramitación de un certificado otorgado por la Comunidad Judíade
              Portugal que avala lo encontrado en el punto anterior, dando así
              un reconocimiento oficial a los antepasados
            </li>
            <br />
            <li>
              Presentación del expediente junto con todas las pruebas necesarias
              ante el Ministerio de Justicia de Portugal. En este punto, el
              Gobierno portugués decidirá si el interesado pudo acreditar su
              herencia judío sefardí.
            </li>
            <br />
          </ol>
          Una vez que el trámite fue concluido y se obtiene un resultado
          satisfactorio, el interesado tendrá acceso a los mismos derechos que
          si hubiera nacido en Portugal, así como las obligaciones, en caso de
          que decida residir en el mismo.
          <br />
          <br />
          Es importante mencionar que, si bien el trámite puede parecer
          sencillo, se basa en una serie de investigaciones que requieren mucho
          tiempo y estudio por parte de expertos en la materia y que inclusive,
          aún haciéndose de la manera correcta, no se puede asegurar que todos
          los interesados obtengan un resultado favorable.
          <br />
          <br />
        </h5>
      </div>
    );
  }

  renderContent2() {
    return (
      <div className="text-content pb-5">
        <h5 className="py-2">
          PROPOSICIÓN DE LEY EN MATERIA DE LA CONCESIÓN DE LA NACIONALIDAD
          ESPAÑOLA A LOS DESCENDIENTES NACIDOS EN EL EXTRANJERO DE PROGENITORES
          ESPAÑOLES
          <br />
          <br />
          Esta nueva Proposición de Ley para 2020, en materia de concesión de la
          nacionalidad española a los descendientes de progenitores españoles,
          nacidos en el extranjero busca reparar situaciones injustas o
          asimétricas surgidas por la falta de reconocimiento de la nacionalidad
          española debido a un vacío legal en la Ley de Memoria Histórica
          <br />
          <br />
          Sin embargo, no todos los descendientes la podrán solicitar. La nueva
          regulación prevé únicamente la posibilidad de conceder la ciudadanía
          en 4 situaciones:
          <br />
          <br />
          <ol className="px-5 pt-2 tc">
            <li>
              Nietos de mujeres españolas que nacieron en España y se casaron
              con ciudadanos no españoles antes de la entrada en vigor de la
              Constitución de 1978.
            </li>
            <br />
            <li>
              Hijos de quienes obtuvieron la nacionalidad española por origen
              (por memoria histórica), que no pudieron convertirse en ciudadanos
              españoles cuando la ley entró en vigor ya que tenían más de 18
              años en ese momento.
            </li>
            <br />
            <li>
              Nietos de españoles que abandonaron el país y obtuvieron la
              ciudadanía del país al que se mudaron; perdiendo así la española
              antes de que nacieran sus hijos.
            </li>
            <br />
            <li>
              Nietos de ciudadanos españoles que incluso pudiendo conseguir la
              nacionalidad, perdieron su opción ya que no expresaron el deseo de
              conservarla después de ser mayores de edad (18 años), por falta de
              una instrucción específica para este supuesto en particular, que
              dejó esa posibilidad a la interpretación de cada registro
              consular.
            </li>
          </ol>
          <br />
          <br />
          {this.renderArrow()}
          <span className="pt-2 pl-2 h4 bold">Nacionalidad por residencia</span>
          <br />
          <br />
          {this.renderArrow()}
          <span className="pt-2 pl-2 h4 bold">Nacionalidad por opción</span>
          <br />
          <br />
          {this.renderArrow()}
          <span className="pt-2 pl-2 h4 bold">Nacionalidad por matrimonio</span>
        </h5>
      </div>
    );
  }

  renderOtros() {
    return (
      <div className="text-content pb-5">
        <h5 className="py-2">
          <ol className="px-5 tc">
            <li>Matrimonio</li>
            <br />
            <li>Parejas de hecho</li>
            <br />
            <li>Reagrupación familiar</li>
            <br />
            <li>Tarjeta de familiar de ciudadano de comunitario</li>
            <br />
            <li>Residencia por arraigo</li>
            <br />
            <li>Prórroga de estancia</li>
            <br />
            <li>Autorización de regreso</li>
            <br />
            <li>Certificado Europeo</li>
            <br />
            <li>Asignación de NIE Temporal.</li>
            <br />
            <li>
              Trámites administrativos: empadronamiento, tarjeta sanitaria, etc.
            </li>
            <br />
          </ol>
        </h5>
      </div>
    );
  }

  render() {
    const { title } = this.state.cover;
    return (
      <div className="container">
        <Menu />
        <div className="pt-15">
          <div className="row">
            {this.renderBack()}
            <div className="col-12 py-3">{this.renderCoverImage()} </div>
            <div className="col-12">
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">{title}</span>
              </h4>
              {this.renderContent()}
              <hr />
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">
                  Nacionalidad por Descendencia:{" "}
                  <strong>(NO HA ENTRADO EN VIGENCIA)</strong>
                </span>
              </h4>
              {this.renderContent2()}
              <hr />
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">Otros Procedimientos</span>
              </h4>
              {this.renderOtros()}
            </div>
            {this.renderBack()}
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Otros;
