import React, { Component, Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import MyContext from "../MyContext";
import { slide as Menu } from "react-burger-menu";

// Components

// Images
import Logo from "../images/logo.svg";

class WebMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMenuLeft() {
    return (
      <div>
        <div className="float-left pl-3">
          <NavLink exact to={`/`} activeClassName="current">
            <h4 className="menu-link">
              <img src={Logo} className={`logo`} alt="Porta Logo" />
            </h4>
          </NavLink>
        </div>
      </div>
    );
  }

  renderMenuCenter() {
    return (
      <div className="text-center">
        <div className="inline-block px-3">
          <NavLink exact to={`/visados`} activeClassName="current">
            <h4 className="menu-link">Visados</h4>
          </NavLink>
        </div>
        <div className="inline-block px-3">
          <NavLink exact to={`/empresas-startups`} activeClassName="current">
            <h4 className="menu-link">Empresas/Startups</h4>
          </NavLink>
        </div>
        <div className="inline-block px-3">
          <NavLink exact to={`/podcast`} activeClassName="current">
            <h4 className="menu-link">Podcast</h4>
          </NavLink>
        </div>
      </div>
    );
  }

  renderMenu() {
    const { link, work, back, foward } = this.props;

    return (
      <div className="row m-0">
        <div className="col-3">{this.renderMenuLeft()}</div>
        <div className="col-6 px-0">
          {this.renderMenuCenter(work, back, foward, link)}
        </div>

        <div className="col-3">
          <div className="float-right"></div>
        </div>
      </div>
    );
  }

  renderHamburgerMenu() {
    return (
      <div className="row m-0">
        {this.renderMenuLeft()}
        <Menu right width={"70%"}>
          <Link to={`/visados`}>Visados</Link>
          <Link to={`/empresas-startups`}>Empresas</Link>
          <Link to={`/podcast`}>Podcast</Link>
        </Menu>
      </div>
    );
  }

  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <Fragment>
            <div className="">
              <header className="menu">
                <div className="d-lg-none">{this.renderHamburgerMenu()}</div>
                <div className="d-none d-lg-block">{this.renderMenu()}</div>
              </header>
            </div>
          </Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}

export default WebMenu;
