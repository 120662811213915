import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyContext from "../MyContext";
import Rating from "react-rating";
import { Timeline } from "react-twitter-widgets";

// Components
import Menu from "./Menu";
import Footer from "./Footer";

// Icons & Images
import StarIconEmpty from "../images/icons/StarIconEmpty";
import StarIconFull from "../images/icons/StarIconFull";
import Linkedin from "../images/icons/linkedin.png";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTeam: true,
      RRSS: [
        {
          name: `LinkedIn`,
          icon: `LinkedinIcon`,
          link: `https://www.linkedin.com/company/portaservicios/`
        },
        {
          name: `Twitter`,
          icon: `TwitterIcon`,
          link: `https://twitter.com/porta_servicios`
        },
        {
          name: `Instagram`,
          icon: `InstagramIcon`,
          link: `https://www.instagram.com/portaservicios/`
        },
        {
          name: `Facebook`,
          icon: `FacebookIcon`,
          link: `https://www.facebook.com/Portaservicios-103489181346762`
        },
        {
          name: `Youtube`,
          icon: `YoutubeIcon`,
          link: `https://www.youtube.com/channel/UCyaM0__fJNfXhaM7-FmbuFg`
        }
      ],
      services: [
        {
          title: `Procesos migratorios y visados`,
          description: `Soluciones a la medida de nuestros clientes en la gestión y procesos de solicitud de visas.`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fhome-1.jpg?alt=media&token=6d74428e-39b1-412e-9e04-c49774b73e7d`,
          link: `visados`
        },
        {
          title: `Creación de empresas en España`,
          description: `Asesorías legales y financieras para la creación de empresas. Algunos de nuestros trabajos, aquí.`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs2.jpeg?alt=media&token=c0596b9f-8782-4bca-b658-d44f0b21a83d`,
          link: `empresas-startups`
        },
        {
          title: `Porta Talks`,
          description: `Un podcast en el que indagamos en los procesos migratorios y diferentes historias de inmigrantes.`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs4.jpg?alt=media&token=ab862ea1-5234-4f53-a678-776a890d44b9`,
          link: `podcast`
        }
      ],
      review: [
        {
          rating: 4.5,
          description: `“Gracias a la asesoría de Glen, pude conseguir la mejor manera de irme de mi país a España, dándome varias opciones de acuerdo a mi perfil profesional y económico, ajuste el mejor visado para estar legal y tranquilo en España. Siempre hubo una comunicación directa, clara y transparente acerca del trámite, realmente me siento satisfecho.”`,
          name: `Juan Manuel Cuellar`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs3.jpg?alt=media&token=14e89516-2157-4a99-9616-1c812ea02515`
        },
        {
          rating: 5,
          description: `“Me encanta todo el equipo de Porta, he tramitado varios trámites con ellos, me han apoyado desde mi llegada a españa, hasta el desarrollo de mi emprendimiento, confío plenamente en su trabajo.”`,
          name: `Jorge Gaviria Parada`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs3.jpg?alt=media&token=14e89516-2157-4a99-9616-1c812ea02515`
        },
        {
          rating: 4.8,
          description: `“Todos en mi familia tramitamos con Glen nuestra nacionalidad por origen sefardí y siempre ha estado muy involucrada en todo el proceso, desde la primera vez hasta que firmamos en Madrid, siempre nos demostró un gran compromiso y profesionalidad, la recomiendo a ella y a todo su equipo”.`,
          name: `Claudia Madrid Bellorin`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs3.jpg?alt=media&token=14e89516-2157-4a99-9616-1c812ea02515`
        }
      ],
      quote: {
        image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fquote-glen-home-cover.png?alt=media&token=634ab69f-0c84-45ad-bcb9-2eea63d66393`,
        description: `“La migracion siempre ha sido uno de los impulsores mas importantes en el proceso y dinamismo humano”`,
        name: `Ian Goldin`
      },
      team: [
        {
          name: `Glen Colmenares`,
          linkedin: `/glenyscolmenares`,
          linkedinName: `/glenyscolmenares`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2FGlen2.jpg?alt=media&token=97fd9aea-ae45-4206-8480-053bf3c0b471`
        },
        // {
        // 	name: `Carlos Moret`,
        // 	linkedin: `/cemoret`,
        // 	linkedinName: `/cemoret`,
        // 	image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fcemoret.jpg?alt=media&token=fc8a9657-63e0-448b-8dca-0d87fdc95397`
        // },
        // {
        //   name: `Alex Sanchez`,
        //   linkedin: `/alex-sanchez-21007435`,
        //   linkedinName: `/alex-sanchez`,
        //   image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Falex.jpg?alt=media&token=449a62fb-da2b-4b0b-a28e-77eee79f64f1`
        // },
        {
          name: `Lisbeth Blanca`,
          linkedin: `/lisbethblanca`,
          linkedinName: `/lisbethblanca`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Flisbeth2.jpg?alt=media&token=68602f04-6ad5-4c21-8be3-0d2e99a9db15`
        },
        {
          name: `Stella Guerrero`,
          linkedinName: `/stella-guerrero`,
          linkedin: `/stella-guerrero-rojas-3a7abb98`,
          image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fstella2.jpg?alt=media&token=248c9e89-787e-455b-bfce-012c7df14de3`
        }
        // {
        //   name: `Natalie Luis`,
        //   linkedinName: `/natalieluis`,
        //   linkedin: `/natalieluis`,
        //   image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fnatalie_luis%202.png?alt=media&token=860bd67c-5d97-44d8-8144-f615f19d2599`
        // }
      ],
      universo: [
        {
          title: `Raheem`,
          link: `https://raheem.es/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FRaheem.png?alt=media&token=e6b421e0-ebe9-4246-9c25-a61353c81180`
        },

        // {
        //   title: `PortaTalks`,
        //   link: `https://portaservicios.com/`,
        //   photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FGroup.png?alt=media&token=13479725-c400-484b-a171-a244b1e4ccc1`
        // },
        {
          title: `Santander`,
          link: `https://www.bancosantander.es/particulares`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FSantander.png?alt=media&token=3cb2317c-7b33-4e66-a44f-2d6ba78f12ca`
        },
        // {
        //   title: `WorkCafe`,
        //   link: `https://www.bancosantander.es/particulares`,
        //   photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FWorkCafe.png?alt=media&token=823905f1-d9a1-412a-bf33-3686033e23cd`
        // },
        {
          title: `ie`,
          link: `https://www.ie.edu/business-school/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2Fie.png?alt=media&token=de444ed3-d710-40c1-956a-46d9c498c6cd`
        },
        {
          title: `MinisterioDeJusticia`,
          link: `https://www.mjusticia.gob.es/es`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FGobierno.png?alt=media&token=905fbb6e-de12-4128-be19-df09e195919e`
        },
        {
          title: `Sanitas`,
          link: `https://www.sanitas.es/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2Fsanitas.png?alt=media&token=4a28f222-9ed1-45cd-8a18-270297558796`
        },
        {
          title: `Latam`,
          link: `https://latamnetworks.es/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FLatam.png?alt=media&token=d7ed3fad-b18a-451a-992d-503bc909e2d6`
        },
        {
          title: `Lux-Andalux`,
          link: `https://luxandalus.es/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2FLux-Andalus.png?alt=media&token=8f280626-7a60-49f0-9a02-cc681d172abb`
        },
        {
          title: `Iwofly`,
          link: `https://www.iwofly.com/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2Fiwofly.png?alt=media&token=c2b734aa-578c-42b0-859e-ba4e12738e4a`
        },
        {
          title: `GrupoExpress`,
          link: `http://serviciosexpresos.com/`,
          photo: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/universo-logos%2Fexpresos.png?alt=media&token=7c61c440-cac7-4c55-9f22-61a5017d793c`
        }
      ]
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  renderServices() {
    const { services } = this.state;
    return services.map(({ title, description, image, link }, index) => {
      return (
        <div key={index} className="col-md-4 col-12 p-4">
          <Link to={link}>
            <div className="box p-4">
              <div
                className={`img-work`}
                style={{
                  backgroundImage: `url(${image})`
                }}
                alt={title}
              />
              <h4 className="font-serif bold pt-2">{title}</h4>
              <h5 className="light pb-5">{description}</h5>
              <div className="fix-bottom-right">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
                  className={`icon`}
                  alt={`arrow`}
                />
              </div>
            </div>
          </Link>
        </div>
      );
    });
  }

  renderQuote() {
    const { image, name, description } = this.state.quote;
    return (
      <div className="col-12 p-4">
        <div className="color-brand">
          <img src={image} className={`img-quote`} alt={name} />

          <h2 className="font-serif light p-3">{description}</h2>
          <h5 className="text-right px-3 pb-3">{name}</h5>
        </div>
      </div>
    );
  }

  renderReview() {
    const { review } = this.state;
    return review.map(({ starts, description, name, image }, index) => {
      return (
        <div key={index} className="col-md-4 col-12 p-4">
          <div className="box-r p-4">
            <h5 className="light">{description}</h5>
            <div>
              <Rating
                className="align-middle py-3"
                style={{ position: "relative" }}
                initialRating={Number(review[index].rating)}
                readonly={true}
                emptySymbol={<StarIconEmpty className="iconStar" />}
                fullSymbol={<StarIconFull className="iconStar" />}
              />
            </div>
            <div className="row mx-0">
              <div className="inline-block"></div>
              <span className="h5 m-0 font-serif pt-2 regular">{name}</span>
            </div>
          </div>
        </div>
      );
    });
  }

  renderUniverso() {
    const { universo } = this.state;
    return universo.map(({ title, link, photo }, index) => {
      return (
        <div key={index} className="col-lg-2 col-md-3 col-sm-4 col-6 p-4">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="vertical-center">
              <img src={photo} className={`img-universo`} alt={title} />
            </div>
          </a>
        </div>
      );
    });
  }

  renderUniverso2() {
    return (
      <div className="col-12 px-4">
        <h3>Universo Porta</h3>
        <div>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Funiverso.png?alt=media&token=f7ccad20-7b64-4581-a4c0-4c750d98f855`}
            className={`img-universo`}
            alt={`Universo Porta`}
          />
        </div>
      </div>
    );
  }

  renderMap() {
    return (
      <div className="col-12 px-4">
        <div className="row">
          <div className="col-md-6 col-12 px-0">
            <h3>Contacto</h3>
            <a
              href="https://goo.gl/maps/G8oZvcVsneAJ29P4A"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4 className="link py-1">
                Club Raheem: Calle de Goya 18, piso 5, 5 izquierda,, Madrid
              </h4>
            </a>
            <a
              href="tel:+34661466402"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4 className="link py-1">T +34 661 46 64 02</h4>
            </a>
            <a
              href="mailto:glen@portaservicios.com?Subject=Hola%20Porta%20desde%20la%20pagina%20web"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4 className="link">glen@portaservicios.com</h4>
            </a>
            <h4>Contacto: Glen Colmenares</h4>
          </div>
          <div className="col-md-6 col-12 px-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.272479933006!2d-3.6860052846038087!3d40.424964979364205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422897c04077b3%3A0xd2ac8af6d04f8f04!2sCalle%20de%20Goya%2C%2018%2C%2028001%20Madrid!5e0!3m2!1sen!2ses!4v1613000520878!5m2!1sen!2ses"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="Map Porta"
              className="google-map"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  renderTeam() {
    const { showTeam } = this.state;
    if (showTeam) {
      return (
        <div className="row m-0 team">
          <div className="col-12 px-5 py-5">
            <h3 className="px-4">Equipo</h3>
            <div className="row m-0 p-team">{this.renderTeamPeople()} </div>
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  }

  renderTeamPeople() {
    const { team } = this.state;
    return team.map(({ name, linkedin, linkedinName, image }, index) => {
      return (
        <div key={index} className="col-md-4 col-sm-6 col-12 p-4">
          <a
            href={`https://www.linkedin.com/in${linkedin}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="text-center p-4">
              <img src={image} className={`avatar-team`} alt={name} />
              <h4 className="font-serif pt-2 m-0 medium">{name}</h4>
              <img src={Linkedin} className={`icon pr-2`} alt={name} />
              <h5 className="font-serif m-0 medium inline-block">
                {linkedinName}
              </h5>
            </div>
          </a>
        </div>
      );
    });
  }

  renderTweets() {
    return (
      <div className="row">
        <div className="col-6">
          <h3 className="pb-3">Porta News</h3>
          <Timeline
            dataSource={{
              sourceType: "profile",
              screenName: "porta_servicios"
            }}
            options={{
              width: "720",
              height: "600",
              chrome: "nofooter"
            }}
          />
        </div>
        <div className="col-6">
          <h3 className="pb-3">Recomendaciones</h3>
          <Timeline
            dataSource={{ sourceType: "likes", screenName: "porta_servicios" }}
            options={{
              width: "720",
              height: "600",
              chrome: "nofooter"
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    var header = "this is porta";
    return (
      <MyContext.Consumer>
        {context => (
          <Fragment>
            <div className="container">
              <Menu />
              <div className="pt-15">
                <div className="row p-5">
                  <div className="hxl light text-center col-12">
                    <div className="h-back">{header.toUpperCase()}</div>
                  </div>
                  {this.renderServices()}
                  {this.renderQuote()}
                  <div className="col-12 py-5">{this.renderTweets()}</div>

                  {this.renderReview()}
                  <div className="col-12 py-5">
                    <hr />
                  </div>
                  <div className="col-12">
                    <h3 className="">Universo Porta</h3>
                  </div>
                  {this.renderUniverso()}

                  <div className="col-12 py-5">
                    <hr />
                  </div>
                  {this.renderMap()}
                </div>

                {this.renderTeam()}
              </div>

              <Footer />
            </div>
          </Fragment>
        )}
      </MyContext.Consumer>
    );
  }
}

export default Home;
