import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Visados extends Component {
	constructor(props) {
		super(props);
		this.state = {
			services: [
				{
					title: `Visado o autorización de residencia para `,
					bold: `Inversor`,
					link: `inversor`
				},
				{
					title: `Visado o autorización de residencia para `,
					bold: `Emprendedores`,
					link: `emprendedores`
				},
				{
					title: `Visado `,
					bold: `no Lucrativo`,
					link: `no-lucrativo`
				},
				{
					title: `Visado o autorización de residencia para `,
					bold: `Personal Altamente Cualificado`,
					link: `personal-altamente-cualificado`
				},
				{
					title: `Visado o autorización de residencia para `,
					bold: `Prácticas`,
					link: `practicas`
				},
				{
					title: `Visado de Estudiante `,
					bold: `Estudiante`,
					link: `estudiante`
				},
				{
					title: `Autorización de residencia para `,
					bold: `búsqueda de empleo por cuenta propia o ajena`,
					link: `busqueda-empleo`
				},
				{
					title: ``,
					bold: `Nacionalidades`,
					link: `otros`
				}
			]
		};
	}

	renderServices() {
		const { services } = this.state;
		return services.map(({ title, bold, link }, index) => {
			return (
				<div key={index} className="col-xl-3 col-lg-4 col-sm-6 col-12 p-3">
					<Link to={`visados/${link}`}>
						<div className="box-select p-4">
							<h3 className="font-serif medium pt-2 pb-5">
								{title}
								<span className="bold">{bold}</span>
							</h3>
							<div className="fix-bottom-right">
								<img
									src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
									className={`icon`}
									alt={`arrow`}
								/>
							</div>
						</div>
					</Link>
				</div>
			);
		});
	}

	render() {
		var header = "visados";
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						<div className="hxl light text-center col-12">
							<div className="h-back">{header.toUpperCase()}</div>
						</div>
					</div>
					<div className="pb-5 px-5">
						<div className="row">{this.renderServices()}</div>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Visados;
