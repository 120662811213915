import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Valores extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs2.jpeg?alt=media&token=c0596b9f-8782-4bca-b658-d44f0b21a83d`,
				title: `Nuestros valores`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h3 className="">Trato humano y cercano</h3>
				<h5 className="pb-4">
					Entendemos que al tratarse de una decisión de cambio de vida, el
					proceso migratorio lleva intrínseco un alto grado de incertidumbre. Es
					por ello que en Porta tenemos una relación cercana con nuestros
					clientes. Comprometidos con sus objetivos, asumimos cada caso con
					respeto y brindamos una asesoría clara y transparente, haciéndolos
					parte de toda la evolución de su proceso.
				</h5>
				<h3 className="">Lealtad y confidencialidad</h3>
				<h5 className="pb-4">
					La lealtad es la base de nuestras políticas. Valoramos profundamente
					la confianza depositada en nosotros, por lo que somos rigurosos con
					nuestras garantías de confidencialidad. Manejamos con absoluta
					discreción toda la información proporcionada por nuestros clientes.
				</h5>
				<h3 className="">Eficiencia y celeridad</h3>
				<h5 className="pb-4">
					Un valor irrenunciable es la eficiencia, adecuando la planificación a
					cada caso en particular para obtener los resultados buscados de la
					manera más rápida posible.
				</h5>
				<h3 className="">Resolución de problemas</h3>
				<h5 className="pb-4">
					Nuestro cliente es el corazón de Porta y estamos aquí para resolver
					sus problemas migratorios y comerciales. Asumiendo el reto de hacer
					realidad su proyecto.
				</h5>
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
						</div>
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default Valores;
