import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components

//Icons

import LinkedinIcon from "../images/rrss/linkedin.png";
import FacebookIcon from "../images/rrss/facebook.png";
import InstagramIcon from "../images/rrss/instagram.png";
import TwitterIcon from "../images/rrss/twitter.png";
import YoutubeIcon from "../images/rrss/youtube.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderSocialMedia() {
    return (
      <div className="">
        <a
          href={`https://twitter.com/porta_servicios`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-2"
        >
          <img src={TwitterIcon} className={`rrss-icon`} alt={TwitterIcon} />
        </a>
        <a
          href={`https://www.linkedin.com/company/portaservicios/`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-2"
        >
          <img src={LinkedinIcon} className={`rrss-icon`} alt={LinkedinIcon} />
        </a>
        <a
          href={`https://www.youtube.com/channel/UCyaM0__fJNfXhaM7-FmbuFg`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-2"
        >
          <img src={YoutubeIcon} className={`rrss-icon`} alt={YoutubeIcon} />
        </a>
        <a
          href={`https://www.instagram.com/portaservicios/`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-2"
        >
          <img
            src={InstagramIcon}
            className={`rrss-icon`}
            alt={InstagramIcon}
          />
        </a>
        <a
          href={`https://www.facebook.com/Portaservicios-103489181346762`}
          target="_blank"
          rel="noopener noreferrer"
          className="px-2"
        >
          <img src={FacebookIcon} className={`rrss-icon`} alt={FacebookIcon} />
        </a>
      </div>
    );
  }

  render() {
    var YEAR = new Date().getFullYear();

    return (
      <footer className="footer p-5">
        <div className="">
          <div className="row p-1 pb-3">
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <Link to={`/quienes-somos`}>
                <h4 className="link py-1">¿Quiénes somos?</h4>
              </Link>
              <Link to={`/valores`}>
                <h4 className="link py-1">Nuestros Valores</h4>
              </Link>
              <Link to={`/porque`}>
                <h4 className="link py-1">¿Porqué España?</h4>
              </Link>
            </div>

            <div className="col-md-4 col-sm-6 col-12 px-1">
              <h4 className="py-1">Contacto</h4>

              <a
                href="https://goo.gl/maps/G8oZvcVsneAJ29P4A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="link py-1">
                  Calle de Goya 18, piso 5, 5 izquierda <br />
                  Club Raheem GL, Madrid
                </h5>
              </a>
              <a
                href="tel:+34661466402"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="link py-1">T +34 661 46 64 02</h5>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <Link to={`/visados`}>
                <h4 className="link py-1">Tipos de Visas</h4>
              </Link>
              <Link to={`/empresas-startups`}>
                <h4 className="link py-1">Empresas/Startups</h4>
              </Link>
              <Link to={`/visados/otros`}>
                <h4 className="link py-1">Otros Servicios</h4>
              </Link>
            </div>
          </div>
          <div className="row p-1 pb-3">
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <h5 className="py-1">@{YEAR} Porta Servicios</h5>
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1">
              <a
                href="mailto:glen@portaservicios.com?Subject=Hola%20Porta%20desde%20la%20pagina%20web"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="link">glen@portaservicios.com</h5>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 col-12 px-1"></div>
            <div className="col-12 pt-5 px-0">{this.renderSocialMedia()}</div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
