import React, { Component } from "react";
import { Link } from "react-router-dom";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class BusquedaEmpleo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cover: {
				image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/covers%2Fcalle.jpeg?alt=media&token=3e2b6cf0-4b64-4a4d-9e24-78075f90796e`,
				title: `Autorización de residencia para búsqueda de empleo o para emprender un proyecto empresarial: (Real Decreto Ley 11/2018)`
			}
		};
	}

	renderBack() {
		return (
			<div className="col-12">
				<Link to={`/visados`}>
					<h4>
						<img
							src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrowBack.svg?alt=media&token=125439d5-b24d-4d81-8756-7d8d13ac35f3`}
							className={`icon inline-block`}
							alt={`arrow`}
						/>
						<span className="pl-2">Atras</span>
					</h4>
				</Link>
			</div>
		);
	}

	renderArrow() {
		return (
			<img
				src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
				className={`icon`}
				alt={`arrow`}
			/>
		);
	}

	renderCoverImage() {
		const { image, title } = this.state.cover;
		return <img src={image} className={`img-quote`} alt={title} />;
	}

	renderContent() {
		return (
			<div className="text-content pt-4 pb-5">
				<h5 className="">
					Con el fin de potenciar la atracción de estudiantes internacionales,
					una vez finalizados los estudios en una institución de educación
					superior, los extranjeros que hayan alcanzado como mínimo el nivel 6,
					de acuerdo al Marco Europeo de Cualificaciones correspondiente a la
					acreditación de grado, podrán permanecer en España durante un período
					máximo e improrrogable de doce (12) meses, con el fin de buscar un
					empleo adecuado en relación con el nivel de los estudios finalizados o
					para emprender un proyecto empresarial.
					<br />
					<br />
					Esta autorización no implica el reconocimiento del derecho a trabajar.
				</h5>
			</div>
		);
	}

	renderQandA() {
		return (
			<div className="text-content py-4">
				<h3 className="py-3">
					Una vez culminados los estudios, el estudiante tiene la posibilidad de
					permanecer en España de forma legal y solicitar un autorización de
					residencia, por cuenta ajena o desarrollando su propio emprendimiento.
				</h3>

				<h5 className="py-2">
					<span className="bold">
						¿Qué requisitos se deben cumplir para solicitar esta residencia?
					</span>
					<ul className="px-5 pt-3 tc">
						<li>
							Haber alcanzado como mínimo el nivel 6 de acuerdo al Marco Europeo
							de cualificaciones (Grado) RD 22/2015 (nivel 7 master y nivel 8
							doctorado)
						</li>
						<br />
						<li>Título o certificado de Educación Superior obtenido</li>
						<br />
						<li>Carta de culminación de los estudios realizados</li>
						<br />
						<li>
							Seguro médico que cubra las mismas prestaciones que la Seguridad
							Social en España
						</li>
						<br />
						<li>Mantenimiento de recursos (económicos) suficientes.</li>
					</ul>
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Con esta autorización se puede trabajar?
					</span>
					<br />
					<br />
					Esta autorización No concede el permiso de trabajo, fue diseñada para
					que en el periodo máximo e improrrogable de hasta un año, el
					estudiante que culminó sus estudios y aún no tenga definida su
					situación laboral, pueda permanecer legalmente en España, mientras
					consigue un empleo adecuado o emprenda su propio proyecto empresarial.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Cuándo se puede solicitar esta autorización de residencia?
					</span>
					<br />
					<br />
					Se solicita durante los sesenta (60) días naturales previos a la
					caducidad de su autorización de estancia como estudiante o durante los
					noventa (90) días naturales posteriores a la fecha en que hubiera
					finalizado la vigencia de la estancia por estudios.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Qué sucede si durante la vigencia no he encontrado el empleo?
					</span>
					<br />
					<br />
					Es una residencia que no permite renovación. Se debe conseguir trabajo
					por cuenta ajena o existe la opción, antes del vencimiento, de
					emprender un proyecto empresarial y solicitar la modificación de
					régimen por autorización de residencia por cuenta propia, de acuerdo a
					los requisitos exigidos para este tipo de residencias.
				</h5>

				<h5 className="py-2">
					<span className="bold">
						¿Qué sucede una vez se consiga un empleo?
					</span>
					<br />
					<br />
					En el caso de conseguir un empleo, se deberá tramitar la autorización
					de residencia por cuenta ajena, de acuerdo a lo regulado en la
					LO4/2000.
				</h5>
				<h5 className="py-2">
					<span className="bold">¿Se podría trabajar en cualquier área?</span>
					<br />
					<br />
					No, el empleo deberá estar directamente relacionado con los estudios
					finalizados.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Qué estatus tienen los familiares de los titulares de esta
						residencia?
					</span>
					<br />
					<br />
					Durante la vigencia de la autorización de residencia para la búsqueda
					de empleo o para emprender un proyecto empresarial, podrá estar
					acompañado por sus familiares en caso de que estos ya le hubiesen
					acompañado durante su estancia por estudios. Su permanencia estará en
					todo caso vinculada a la situación del titular de la autorización
					principal.
					<br />
					<br />
					La autorización se expedirá con idéntica validez y duración a la que
					se conceda al extranjero que genera el derecho y se renovará mientras
					se mantengan las circunstancias que motivaron su concesión.
				</h5>
				<h5 className="py-2">
					<span className="bold">
						¿Qué sucede si no se ha obtenido respuesta por parte de la
						administración? ¿La permanencia en España sería ilegal?
					</span>
					<br />
					<br />
					No, la solicitud en el plazo indicado prorroga la validez de la
					autorización anterior hasta la resolución del procedimiento.
				</h5>

				<h5 className="py-2">
					Ventajas de autorización de Residencia:
					<ul className="px-5 pt-2 tc">
						<li>No es obligatorio el retorno al país de origen</li>
						<br />
						<li>Es una residencia, no una estancia.</li>
						<br />
					</ul>
				</h5>

				<div className="pb-5" />
			</div>
		);
	}

	render() {
		const { title } = this.state.cover;
		return (
			<div className="container">
				<Menu />
				<div className="pt-15">
					<div className="row">
						{this.renderBack()}
						<div className="col-12 py-3">{this.renderCoverImage()} </div>
						<div className="col-12">
							<h4>
								{this.renderArrow()}
								<span className="h4 medium pl-2">{title}</span>
							</h4>
							{this.renderContent()}
							<hr />
							{this.renderQandA()}
						</div>
						{this.renderBack()}
					</div>

					<Footer />
				</div>
			</div>
		);
	}
}

export default BusquedaEmpleo;
