import React from "react";
import { Route, Switch } from "react-router-dom";

// General
import Home from "./components/Home";
import Visados from "./components/visados/visados";
import Empresas from "./components/empresas/empresas";
import Podcast from "./components/podcast/podcast";

//visados
import VisadoInversor from "./components/visados/inversor";
import VisadoEmprendedores from "./components/visados/emprendedores";
import NoLucrativo from "./components/visados/no-lucrativo";
import VisadoPAC from "./components/visados/profesional-altamente-cualificado";
import VisadoEstudiante from "./components/visados/estudiante";
import VisadoBusquedaEmpleo from "./components/visados/busqueda-empleo";
import VisadoPracticas from "./components/visados/practicas";
import VisadoOtros from "./components/visados/otros";

//Footer
import QuienesSomos from "./components/footer/quienes-somos";
import Valores from "./components/footer/valores";
import PorqueEspana from "./components/footer/porque-espana";

export default () => {
	return (
		<div>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/visados" component={Visados} />
				<Route exact path="/visados/inversor" component={VisadoInversor} />
				<Route
					exact
					path="/visados/emprendedores"
					component={VisadoEmprendedores}
				/>
				<Route exact path="/visados/no-lucrativo" component={NoLucrativo} />
				<Route
					exact
					path="/visados/personal-altamente-cualificado"
					component={VisadoPAC}
				/>
				<Route exact path="/visados/estudiante" component={VisadoEstudiante} />
				<Route
					exact
					path="/visados/busqueda-empleo"
					component={VisadoBusquedaEmpleo}
				/>
				<Route exact path="/visados/practicas" component={VisadoPracticas} />
				<Route exact path="/visados/otros" component={VisadoOtros} />
				<Route exact path="/empresas-startups" component={Empresas} />
				<Route exact path="/podcast" component={Podcast} />

				<Route exact path="/quienes-somos" component={QuienesSomos} />
				<Route exact path="/valores" component={Valores} />
				<Route exact path="/porque" component={PorqueEspana} />
			</Switch>
		</div>
	);
};
