import React, { Component } from "react";

// Components
import Menu from "../Menu";
import Footer from "../Footer";

class Podcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cover: {
        image: `https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/home%2Fs3.jpg?alt=media&token=14e89516-2157-4a99-9616-1c812ea02515`,
        title: `Porta Talks`
      }
    };
  }

  renderCoverImage() {
    const { image, title } = this.state.cover;
    return <img src={image} className={`img-quote`} alt={title} />;
  }

  renderContent() {
    return (
      <div className="text-content pt-4 pb-5">
        <h5 className="">
          Porta Talks nace como una iniciativa de “ciudadanos extranjeros” que
          abrazaron la migración como una oportunidad, como parte de una
          evolución que aunque sucede por razones estrictamente individuales,
          conserva similitudes que al compartirlas, pueden ayudar a otros a
          transitar su propio camino.
          <br />
          <br />
          Conoceremos distintas historias a través de los relatos de nuestros
          invitados, de conversaciones sobre superación personal y profesional,
          de sus experiencias en el “ensayo y error”. Algunos de ellos con metas
          materializadas, otros que están recorriendo el camino hacia sus sueños
          y también varios que están comenzando a proyectar su futuro y evalúan
          las distintas opciones para comenzar a trabajar por ellos. Escuchando
          atentamente las distintas visiones del viaje que todos hacemos hacia
          nuestros objetivos y entendiendo que el éxito tiene diversas formas de
          manifestarse para cada quien.
        </h5>

        <h4 className=" pt-3">
          Te invitamos a ver nuestros episodios aquí&nbsp;
          <a
            href="https://www.youtube.com/channel/UCyaM0__fJNfXhaM7-FmbuFg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="medium link">Canal de Youtube</span>
          </a>
        </h4>
      </div>
    );
  }

  renderArrow() {
    return (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/icons%2FArrow.svg?alt=media&token=b5b16fcc-d108-404e-992e-0f8ac16e8935`}
        className={`icon`}
        alt={`arrow`}
      />
    );
  }

  render() {
    const { title } = this.state.cover;
    const header = "Podcast";

    return (
      <div className="container">
        <Menu />
        <div className="pt-15">
          <div className="row">
            <div className="hxl light text-center col-12">
              <div className="h-back">{header.toUpperCase()}</div>
            </div>
            <div className="col-12 py-3">{this.renderCoverImage()} </div>
            <div className="col-12">
              <h4>
                {this.renderArrow()}
                <span className="h4 medium pl-2">{title}</span>
              </h4>
              {this.renderContent()}
            </div>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Podcast;
